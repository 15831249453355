* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

:root {
  --primary: #DF7401;
  --light: #F1F8FF;
  --dark:#0F172B;

}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

h1 {
  font-size: 3.438rem;
}

h2 {
  font-size: 2rem;
  @media screen and (max-width: 770px) {
    font-size: 1.5rem;
}

}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
  font-family: 'Pacifico', cursive;
  color: var(--primary);
}

h5 {
  font-size: 1.25rem;
}

::-webkit-scrollbar {
  display: none;
}