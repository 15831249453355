@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins:wght@400;500;600;700&display=swap");

// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import "node_modules/react-modal-video/scss/modal-video.scss";

body {
  font-family: "Montserrat", sans-serif;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  @media screen and (max-width: 1200px) {
    max-width: 960px;
  }
  @media screen and (max-width: 990px) {
    max-width: 720px;
  }
  @media screen and (max-width: 768px) {
    max-width: 540px;
  }
}
.spacing {
  margin-top: 4rem;
  @media screen and (max-width: 1200px) {
    margin-top: 4rem;
  }
  @media screen and (max-width: 990px) {
    margin-top: 3rem;
  }
  @media screen and (max-width: 768px) {
    margin-top: 3rem;
  }
}
.action-button {
  background-color: var(--primary) !important;
}
