.contact-container {
  padding-top: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid white;
  @media screen and (max-width: 990px) {
    flex-direction: column;
  }
  @media screen and (max-width: 770px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .flex {
      display: flex;
      align-items: center;
      gap: 0.7rem;

      .border {
        margin-top: 0.5rem;
        width: 45px;
        height: 2px;
        background-color: var(--primary);
      }
    }

    h2 {
      margin-top: 0.2rem;
    }
  }

  .flex-contact {
    margin-top: 4rem;
    width: 100%;
    display: flex;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .video-section {
      width: 50%;
      background: url("../assets/bg.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 1200px) {
        height: 60vh;
        max-width: 960px;
        width: 100%;
      }

      @media screen and (max-width: 990px) {
        max-width: 720px;
        width: 100%;
      }

      @media screen and (max-width: 770px) {
        max-width: 540px;
        width: 100%;
      }

      body {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      /***********************************************************/

      .startContainer {
        text-align: center;
      }

      /***********************************************************/

      .button {
        color: var(--primary);
        font-family: "Orbitron", Verdana, sans-serif;
        font-size: 2.2em;
        background: var(--primary-color);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        z-index: 5;

        .icon {
          padding: 10px;
          color: black;
          font-size: 90px;
          background-color: var(--primary);
          border-radius: 50%;
        }

        &::before {
          content: "";
          position: absolute;
          z-index: -1;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          display: block;
          width: 80px;
          height: 80px;
          background: var(--primary);
          border-radius: 50%;
          animation: pulse-border 1500ms ease-out infinite;
        }
      }

      @keyframes pulse-border {
        0% {
          transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
          opacity: 1;
        }

        100% {
          transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
          opacity: 0;
        }
      }
    }

    .contact-form {
      width: 50%;
      background-color: var(--dark);
      padding: 2.5rem;

      @media screen and (max-width: 1200px) {
        max-width: 960px;
        width: 100%;
      }

      @media screen and (max-width: 990px) {
        max-width: 720px;
        width: 100%;
      }

      @media screen and (max-width: 770px) {
        max-width: 540px;
        width: 100%;
        padding: 1.2rem;
      }

      .headings {
        display: flex;
        flex-direction: column;

        .flex {
          display: flex;
          align-items: center;
          gap: 0.7rem;

          .border {
            margin-top: 0.5rem;
            width: 45px;
            height: 2px;
            background-color: var(--primary);
          }
        }

        h2 {
          color: #fff;
        }
      }

      .flex-fields {
        .flex-inputs {
          margin-top: 1rem;
          display: flex;
          gap: 1rem;

          @media screen and (max-width: 770px) {
            flex-direction: column;
          }

          .input {
            width: 100%;
            color: white;
            background-color: #fff;
            border-radius: 3px;
          }

          .button {
            padding: 12px 0;
            font-family: "Montserrat", sans-serif;
            width: 100%;
            font-size: 17px;
            background-color: var(--primary);
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
