.home-container {
  width: 100%;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/bg6.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 600px) {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("../assets/bg6.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .fts-group {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      color: var(--primary);
      font-family: "Nunito", sans-serif;
      margin-bottom: 1rem;

      @media screen and (max-width: 990px) {
        text-align: center;
      }

      @media screen and (max-width: 600px) {
        font-size: 3rem;
      }
    }

    h3 {
      color: var(--light);
      font-family: "Nunito", sans-serif;

      @media screen and (max-width: 600px) {
        font-size: 1.5rem;
      }
    }

    .primary-button {
      margin-top: 3rem;

      a {
        color: var(--light);
        background-color: var(--primary);
        padding: 15px 50px;
        font-size: 18px;
        border-radius: 3px;

        @media screen and (max-width: 600px) {
          padding: 15px 40px;
          font-size: 16px;
        }
      }
    }
  }
}
