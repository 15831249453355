.jobs-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 6rem;
  gap: 40px;
  @media screen and (max-width: 990px) {
    flex-direction: column;
  }
  @media screen and (max-width: 770px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 3rem;
  }
  .heading-container {
    width: 100%;
    display: flex;
    justify-content: center;
    .heading {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .flex-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1.5rem;
    justify-self: center;
    padding-bottom: 3rem;

    @media screen and (max-width: 1200px) {
      gap: 1rem;
    }

    @media screen and (max-width: 990px) {
      grid-template-columns: 1fr 1fr;
      gap: 1.5rem;
    }

    @media screen and (max-width: 580px) {
      grid-template-columns: 1fr;
      gap: 1rem;
      padding: 0 20px;
      padding-bottom: 3rem;
    }
    .job-card {
      height: 350px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.1);
      transition: all 1s;

      .image {
        width: 100%;
        height: 200px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .details {
        padding: 12px;
        display: flex;
        gap: 10px;
        flex-direction: column;
        .title {
          font-size: 20px;
          font-weight: bold;
        }
        .title {
          text-align: center;
        }
      }

      .back_drop {
        position: absolute;
        background-color: var(--primary);
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        transition: all 1s;
        margin-top: 420px;
        z-index: -1;
      }

      .logo {
        .icon {
          fill: var(--primary);
          transition: all 1.5s;
        }
      }

      .heading {
        h5 {
          font-weight: 600;
        }
      }

      .para {
        p {
          font-size: 15px;
          text-align: justify;
        }
      }
    }
  }
  .job-detail-container {
    padding-top: 3rem;
    display: flex;
    align-items: center;
    gap: 40px;
    padding-bottom: 5rem;

    @media screen and (max-width: 990px) {
      flex-direction: column;
    }
    @media screen and (max-width: 770px) {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 1rem;
      padding-bottom: 2rem;
    }
    .image {
      height: 400px;
      border-radius: 20px;
      img {
        border-radius: 20px;
        height: 100%;
      }
    }
    .about-box {
      width: 100%;
      margin-top: 0rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      @media screen and (max-width: 990px) {
        margin-top: 0rem;
      }

      .heading {
        display: flex;
        flex-direction: column;

        .flex {
          display: flex;
          align-items: center;
          gap: 0.7rem;

          .border {
            margin-top: 0.5rem;
            width: 45px;
            height: 2px;
            background-color: var(--primary);
          }
        }
        h2 {
          margin-top: 0.2rem;
        }
      }

      .para {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        font-weight: bold;

        p {
          text-align: justify;
        }
      }
    }
  }
  .flex-fields {
    padding-bottom: 50px;
    .flex-inputs {
      margin-top: 1rem;
      display: flex;
      gap: 1rem;

      @media screen and (max-width: 770px) {
        flex-direction: column;
      }

      .input {
        width: 100%;
        color: white;
        background-color: #fff;
        border-radius: 3px;
      }

      .button {
        padding: 12px 0;
        font-family: "Montserrat", sans-serif;
        width: 100%;
        font-size: 17px;
        background-color: var(--primary);
        text-transform: uppercase;
      }
    }
  }
}
