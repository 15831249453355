.flex-events-box {
  display: flex;
  gap: 50px;
  flex-direction: column;
  align-items: center;
  margin: 4rem auto;

  @media (max-width: 990px) {
    margin: 3rem auto;
  }
  .heading {
    display: flex;
    align-items: center;
    flex-direction: column;

    .flex {
      display: flex;
      align-items: center;
      gap: 0.7rem;

      .border {
        margin-top: 0.5rem;
        width: 45px;
        height: 2px;
        background-color: var(--primary);
      }
    }
  }
}
.flex-events {
  width: 100%;
  display: flex;
  gap: 15px;
  flex-direction: column;
  @media (max-width: 770px) {
    flex-direction: column;
    padding: 0 20px;
  }
  .event {
    width: 100%;
    display: flex;
    gap: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    @media (max-width: 770px) {
      flex-direction: column;
    }
    .image {
      width: 110px;
      @media (max-width: 770px) {
        width: 100%;
        height: 200px;
      }
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    .event-details {
      width: 100%;
      padding: 20px 10px;
      display: flex;
      flex-direction: column;
      .flex-content {
        display: flex;
        justify-content: space-between;
        @media (max-width: 770px) {
          flex-direction: column;
        }
        h2 {
          font-weight: 700 !important;
          font-size: 1rem;
          margin-bottom: 0.5rem;
        }
        span {
          font-size: 70%;
          font-weight: 400;
          color: #9a9da2;
          line-height: 1.5;
          margin-bottom: 0.5rem;
        }
      }
      .details {
        font-size: 13px;
        color: #7a7b7c;
      }
    }
  }
}
