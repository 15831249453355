.services-container {
  padding-top: 6rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  @media screen and (max-width: 990px) {
    flex-direction: column;
  }
  @media screen and (max-width: 770px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
.flex-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1.5rem;
  justify-self: center;

  @media screen and (max-width: 1200px) {
    gap: 1rem;
  }

  @media screen and (max-width: 990px) {
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
  }

  @media screen and (max-width: 580px) {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 0 20px;
  }

  .card {
    overflow: hidden;
    position: relative;
    height: 350px;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    justify-content: center;
    align-items: center;
    // padding: 2.5rem 1.5rem 3rem 1.5rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    transition: all 1s;

    // @media screen and (max-width: 1200px) {
    //   padding: 2.5rem 1.5rem 3rem 1.5rem;
    // }

    .back_drop {
      position: absolute;
      background-color: var(--primary);
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      transition: all 1s;
      margin-top: 420px;
      z-index: -1;
    }

    &:hover {
      .back_drop {
        margin-top: 0;
      }
    }

    .logo {
      height: 220px;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .heading {
      h5 {
        font-weight: 600;
      }
    }

    .para {
      p {
        padding: 0 10px 15px 10px;
        font-size: 14px;
        text-align: justify;
      }
    }

    &:hover {
      color: #fff;

      .icon {
        fill: #fff;
      }
    }
  }
}
.headings {
  display: flex;
  flex-direction: column;

  .flex {
    display: flex;
    align-items: center;

    .border {
      margin-top: 0.5rem;
      width: 45px;
      height: 2px;
      background-color: var(--primary);
    }
  }

  h2 {
    color: #fff;
  }
}
