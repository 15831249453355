.flex-about {
  display: flex;
  gap: 3rem;

  @media screen and (max-width: 990px) {
    flex-direction: column;
  }

  @media screen and (max-width: 770px) {
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }

  .images-box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .upper {
      display: flex;
      gap: 1rem;

      .large-image {
        img {
          height: 220px;

          @media screen and (max-width: 1200px) {
            height: 200px;
          }

          @media screen and (max-width: 990px) {
            height: 320px;
          }

          @media screen and (max-width: 770px) {
            height: 200px;
          }

          @media screen and (max-width: 500px) {
            height: 150px;
          }
        }
      }

      .small-image {
        margin-top: 5rem;

        img {
          height: 150px;

          @media screen and (max-width: 1200px) {
            height: 130px;
          }

          @media screen and (max-width: 990px) {
            height: 230px;
          }

          @media screen and (max-width: 770px) {
            height: 140px;
          }

          @media screen and (max-width: 500px) {
            margin-top: -0.5rem;
            height: 100px;
          }
        }
      }
    }

    .lower {
      margin-left: 4.5rem;
      display: flex;
      gap: 1rem;

      @media screen and (max-width: 1200px) {
        margin-left: 5rem;
      }
      @media screen and (max-width: 500px) {
        margin-left: 4.4rem;
      }

      .large-image {
        img {
          height: 220px;

          @media screen and (max-width: 1200px) {
            height: 200px;
          }

          @media screen and (max-width: 990px) {
            height: 320px;
          }

          @media screen and (max-width: 770px) {
            height: 200px;
          }

          @media screen and (max-width: 500px) {
            height: 150px;
          }
        }
      }

      .small-image {
        img {
          height: 150px;

          @media screen and (max-width: 1200px) {
            height: 130px;
          }

          @media screen and (max-width: 990px) {
            height: 230px;
          }

          @media screen and (max-width: 770px) {
            height: 140px;
          }

          @media screen and (max-width: 500px) {
            height: 100px;
          }
        }
      }
    }
  }

  .about-box {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @media screen and (max-width: 990px) {
      margin-top: 0rem;
    }

    .heading {
      display: flex;
      flex-direction: column;

      .flex {
        display: flex;
        align-items: center;
        gap: 0.7rem;

        .border {
          margin-top: 0.5rem;
          width: 45px;
          height: 2px;
          background-color: var(--primary);
        }
      }
      h2 {
        margin-top: 0.2rem;
      }
    }

    .para {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      font-weight: bold;

      p {
        text-align: justify;
      }
    }
  }
}

.flex-services {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  @media screen and (max-width: 770px) {
    padding: 0 20px;
  }

  h2 {
    font-weight: bold;
  }

  p {
    text-align: justify;
    font-weight: bold;
  }
}
