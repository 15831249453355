.footer-container {
  background-color: var(--dark);
  color: var(--light);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .flex-footer {
    padding: 6rem 0 4rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2.7rem;

    @media screen and (max-width: 1200px) {
      gap: 2.7rem;
    }

    @media screen and (max-width: 990px) {
      padding: 5rem 0 4rem 0;
      grid-template-columns: 1fr;
    }

    @media screen and (max-width: 600px) {
      padding: 3rem 1.2rem;
      gap: 2rem;
    }

    .flex-contact {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .heading {
        display: flex;
        align-items: center;
        gap: 0.7rem;

        .border {
          margin-top: 0.5rem;
          width: 45px;
          height: 2px;
          background-color: var(--primary);
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-top: 1rem;

        @media screen and (max-width: 600px) {
          margin-top: 0rem;
        }

        a {
          display: flex;
          gap: 0.5rem;
          color: var(--light);
          cursor: pointer;
          &:hover {
            color: var(--primary);
          }
          .icon {
            font-size: 22px;
          }
        }
      }

      .icons {
        display: flex;
        gap: 0.4rem;

        a {
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--light);
          border: 1px solid #ffffff;
          border-radius: 35px;
          transition: 0.3s;
          .icon {
            font-size: 19px;
          }
        }

        .circle:hover {
          color: #df7401 !important;
          background-color: #fff;
          border-color: #df7401 !important;
        }
      }
    }

    .links {
      overflow: scroll;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .heading {
        display: flex;
        align-items: center;
        gap: 0.7rem;

        .border {
          margin-top: 0.5rem;
          width: 45px;
          height: 2px;
          background-color: var(--primary);
        }
      }

      .scroll-menu {
        height: 22vh;
        background-color: transparent;
        overflow: auto;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        gap: 0.7rem;

        a {
          display: inline-block;
          color: white;
          text-align: center;
          text-decoration: none;
          display: flex;
          align-items: center;
          gap: 0.2rem;
          text-transform: capitalize;
          transition: 0.3s;
          &:hover {
            letter-spacing: 1px;
            box-shadow: none;
          }
        }
      }
    }

    .location {
      width: 100%;

      iframe {
        height: 250px;
        width: 370px;

        @media screen and (max-width: 990px) {
          width: 100%;
        }
      }
    }
  }

  .border {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid rgba(233, 233, 233, 0.3);
  }

  .copyright {
    padding: 30px;

    p {
      text-align: center;
    }

    a {
      color: #fff;
    }
  }
}
