.clients-container {
  padding: 0px 0 120px 0;

  .flex-clients {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .flex {
      display: flex;
      align-items: center;
      gap: 0.7rem;

      .border {
        margin-top: 0.5rem;
        width: 45px;
        height: 2px;
        background-color: var(--primary);
      }
    }

    h2 {
      margin-top: 0.2rem;
    }
  }
}
.slick-dots li button:before {
  font-size: 16px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: gray;
}

li.slick-active button:before {
  color: var(--primary) !important;
}

.slick-dots {
  bottom: -70px;
}

.slide_parent {
  display: flex !important;
  justify-content: center;
  flex-direction: column !important;
  align-items: center !important ;
  width: 200px !important;
  height: 160px;
  gap: 10px;
}
.parent {
  width: 100%;
  margin: 50px auto;

  .text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    text-align: center;

    color: #00a859;
  }

  .slick-list {
    padding: 0 80px !important;

    @media (max-width: 1024px) {
      padding: 0 100px !important;
    }
  }
}

.slider__main {
  height: 720px;

  background: #d9d9d9;
  position: relative;

  .slider__componet {
    position: absolute;
    bottom: 6rem;
    width: 100%;
  }
}
