.about-container {
  padding-top: 6rem;
  display: flex;
  align-items: center;
  gap: 40px;
  @media screen and (max-width: 990px) {
    flex-direction: column;
  }
  @media screen and (max-width: 770px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 3rem;
  }
  .image {
    height: 400px;
    border-radius: 20px;
    img {
      border-radius: 20px;
      height: 100%;
    }
  }
  .about-box {
    margin-top: 0rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @media screen and (max-width: 990px) {
      margin-top: 0rem;
    }

    .heading {
      display: flex;
      flex-direction: column;

      .flex {
        display: flex;
        align-items: center;
        gap: 0.7rem;

        .border {
          margin-top: 0.5rem;
          width: 45px;
          height: 2px;
          background-color: var(--primary);
        }
      }
      h2 {
        margin-top: 0.2rem;
      }
    }

    .para {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      font-weight: bold;

      p {
        text-align: justify;
      }
    }
  }
}
.license {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 50px;
  img {
    width: 100%;
  }
  @media screen and (max-width: 770px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
