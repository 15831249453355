.nav-container {
  width: 100%;
  height: 80px;
  padding: 0rem 3.2rem;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  background-color: var(--dark);
  z-index: 1111;

  @media screen and (max-width: 990px) {
    background-color: var(--dark);
    height: 70px;
    padding: 0rem 2rem;
  }
  @media screen and (max-width: 600px) {
    padding: 0rem 1.2rem;
    height: 60px;
  }
  .logo {
    img {
      max-height: 60px;
      transition: 0.5s;
      @media screen and (max-width: 600px) {
        max-height: 50px;
      }
    }
  }

  .menu {
    .web-menu {
      display: flex;
      gap: 1.8rem;
      align-items: center;

      a {
        font-size: 18px;
        color: var(--light);
        font-weight: 500;

        &:hover {
          color: var(--primary);
        }

        &:active {
          color: var(--primary);
          font-weight: bold;
        }
      }
      .active {
        color: var(--primary);
      }

      .call {
        padding: 10px 30px;
        background-color: var(--primary);
        border-radius: 3px;
        &:hover {
          color: #fff;
        }
      }

      @media screen and (max-width: 990px) {
        display: none;
      }
    }

    .mobile-menu {
      display: none;

      @media screen and (max-width: 990px) {
        display: block;
      }

      .menu {
        color: rgba(247, 232, 204, 0.7);
        border: 1px solid rgba(247, 232, 204, 0.4);
        padding: 1px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
      }
    }
  }
}
.scroll {
  height: 70px;
  background-color: var(--dark);
  z-index: 1111;
  transition: 2s;
  @media screen and (max-width: 990px) {
    background-color: var(--dark);
    height: 70px;
    padding: 0rem 2rem;
  }
  @media screen and (max-width: 600px) {
    padding: 0rem 1.2rem;
    height: 60px;
  }
}
.main {
  padding: 2rem;
  background-color: var(--dark);
  .border {
    margin-top: 1.5rem;
    width: 100%;
    border-bottom: 1px solid rgba(214, 208, 208, 0.2);
  }
  .nav {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    .call {
      width: max-content;
      padding: 10px 30px;
      background-color: var(--primary);
      border-radius: 3px;
      &:hover {
        color: #fff;
      }
    }
    a {
      color: #fff;
      font-size: 18px;
      &:active {
        color: var(--primary);
      }
    }
    .active {
      color: var(--primary);
    }
    .right {
      a {
        padding: 10px 20px;
        background-color: var(--primary);
        border-radius: 3px;
      }
    }
  }
}
