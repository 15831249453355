.testimonial-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 105px 6rem 30px;
  padding: 0px 15px;

  @media (max-width: 1200px) {
    margin: 105px 3.2rem 30px;
  }
  @media (max-width: 768px) {
    margin: 105px 24px 30px;
  }

  h1 {
    padding-bottom: 1rem;
    margin-bottom: 0.5rem;
    color: #232323;
    font-size: 3rem;
    font-style: normal;
    line-height: 1.2;
    word-break: break-word;
    word-wrap: break-word;
    font-weight: 300;
    text-align: center;
    align-self: center;

    @media (max-width: 768px) {
      font-size: 2.4rem;
    }
  }

  .testimonial-card {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;

    @media (max-width: 990px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 580px) {
      grid-template-columns: 1fr;
    }

    .testimonial-img {
      width: 25%;

      @media (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
      }
      .testimonial-image {
        width: 170px;
        height: 236px;
        border: 1px solid #dee2e6;
        border-radius: 5px;
        margin: 1rem auto;
        padding: 0.25rem;
        cursor: pointer;
        transition: all 0.3s ease;
      }
    }
  }

  .popup-media {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1111;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    overflow-y: auto;

    img {
      width: 500px;
      height: 95%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      object-fit: contain;

      @media (max-width: 525px) {
        width: 300px;
        height: 95%;
      }

      @media (max-width: 400px) {
        width: 250px;
      }
    }

    span {
      position: absolute;
      top: 5px;
      right: 20px;
      font-size: 50px;
      font-weight: bolder;
      z-index: 1111;
      cursor: pointer;
      color: #fff;
      user-select: none;
    }
  }
}
